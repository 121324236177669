/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { BASE_API_URL, APP_URL } from "@config/environment";

// == Types ================================================================

// == Constants ============================================================

// == Exports ==============================================================

export enum InstallStatusEnum {
  PENDING_INSTALL = "PENDING_INSTALL",
  PENDING_UPDATE = "PENDING_UPDATE",
  PENDING_DELETE = "PENDING_DELETE",
  INSTALLED = "INSTALLED",
  UPDATED = "UPDATED",
  DELETED = "DELETED",
  ERROR = "ERROR",
}

export const BIG_COMMERCE_APP_CLIENT_ID = process.env.NEXT_PUBLIC_BIG_COMMERCE_APP_CLIENT_ID!;
export const BIG_COMMERCE_APP_CLIENT_SECRET = process.env.BIG_COMMERCE_APP_CLIENT_SECRET!;
export const BIG_COMMERCE_WEBHOOK_SECRET = process.env.BIG_COMMERCE_WEBHOOK_SECRET!;
export const BIG_COMMERCE_APP_ID = process.env.NEXT_PUBLIC_BIG_COMMERCE_APP_ID!;
export const FLIPGIVE_WEBHOOKS_URL = `${BASE_API_URL}/big-commerce/webhooks`;

export const BIG_COMMERCE_APP_MARKETPLACE_ENDPOINT = `/manage/marketplace/apps/${BIG_COMMERCE_APP_ID}`;

export const BIG_COMMERCE_OAUTH_SCOPES = [
  "store_cart_read_only",
  "store_checkout_read_only",
  "store_content_checkout",
  "store_v2_content",
  "store_v2_default",
  "store_v2_orders_read_only",
];
export const AUTH_CALLBACK_URL = `${APP_URL}/api/auth`;

export const FLIPGIVE_SETUP_STATUS_PATH = "/api/install/flipgive-setup/status";
export const TOKEN_STATUS_PATH = "/api/install/token/status";
export const WEBHOOKS_INSTALL_PATH = "/api/install/webhooks";
export const WEBHOOKS_STATUS_PATH = "/api/install/webhooks/status";
export const SCRIPT_TAGS_INSTALL_PATH = "/api/install/script_tags";
export const SCRIPT_TAGS_STATUS_PATH = "/api/install/script_tags/status";

export const USE_DEVELOPMENT_MOCKS = false;
