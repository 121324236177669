import React from "react";
import { EmptyState, Heading } from "@shopify/polaris";
import { useToggle } from "react-use";
import { BIG_COMMERCE_APP_CLIENT_ID } from "@utils";

// == Types ================================================================

// == Constants ============================================================

// == Functions ============================================================

// == Component ============================================================

export default function Home() {
  const [isLoading, toggleIsLoading] = useToggle(false);

  return (
    <EmptyState
      action={{
        id: "login-button",
        content: isLoading ? "Redirecting" : "Install",
        disabled: isLoading,
        external: false,
        onAction: () => {
          toggleIsLoading(true);
          window.location.href = `https://login.bigcommerce.com/app/${BIG_COMMERCE_APP_CLIENT_ID}/install`;
        },
      }}
      image="https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg"
      secondaryAction={{
        content: "Learn more",
        external: true,
        url: "https://www.flipgive.com/pages/contact",
      }}
    >
      <img alt="FlipGive Logo" src="/flipgive_logo_black.png" />
      <Heading>Click here to install the FlipGive BigCommerce app</Heading>
    </EmptyState>
  );
}

// == Styles ===============================================================
